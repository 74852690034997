<template>
    <div class="chats">
        <div v-if="!isLoading" class="card shadow-sm">
            <div class="card-body px-2 py-2 border-bottom">
                <div class="py-1">
                    <div class="d-flex">
                        <b-dropdown left variant="light">
                            <template #button-content>
                                <i class="mdi mdi-dots-vertical"></i>
                            </template>
                            <b-dropdown-item @click="$router.go(-1)" href="#"> <i class="mdi mdi-arrow-left"></i> Back </b-dropdown-item>
                            <b-dropdown-item @click="$router.push({path:`/users/${user.id}`})" href="#"> <i class="mdi mdi-eye"></i> Profile</b-dropdown-item>
                        </b-dropdown>
                        <form class="search-bar flex-grow-1 m-0">
                            <div class="position-relative">
                                <input v-model="searchQuery" type="text" style="padding-left:20px;" class="rounded-0 form-control form-control-white" placeholder="Search for messages..." />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="card-body p-2 ">
                <chat-window ref="chatLogPSWindow" :chats="chats">
                    <template v-slot:footer>
                        <div class="row">
                            <div class="col">
                                <div class="mt-2 p-0 rounded">
                                    <form class="needs-validation" @submit.prevent="sendMessage" name="chat-form" id="chat-form">
                                        <b-input-group>
                                            <b-input-group-append>
                                                <button @click="showUploadFileModal = true" type="button" class="btn btn-light"><i class="fas fa-paperclip"></i></button>
                                            </b-input-group-append>
                                            <b-form-input v-model="form.content" placeholder="Enter your text"></b-form-input>
                                            <b-input-group-append>
                                                <button type="submit" class="btn btn-primary chat-send btn-block">
                                                    <i class="mdi mdi-send"></i>
                                                </button>
                                            </b-input-group-append>
                                        </b-input-group>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </template>
                </chat-window>
            </div>
             <div>
                <b-modal centered v-model="showUploadFileModal" title="Upload Files" header-close-variant="primary" title-class="font-18 text-primary" hide-footer>
                    <upload-attachment @scrollMessagesUp="resetForm(); scrollMessageAreaUp() "/>
                </b-modal>
            </div>
        </div>
        <is-loading v-if="isLoading" />
    </div>
</template>

<script>

import IsLoading from "@/components/IsLoading.vue"
import ChatWindow from "./components/ChatWindow.vue"
import UploadAttachment from "./components/UploadAttachment.vue"

export default {
	components: {
        IsLoading,
        ChatWindow,
        UploadAttachment
	},
	data() {
		return {
            isLoading:true,
            showUploadFileModal:false,
            form:{
                content: ""
            },
		}
	},
    watch: {
        "$route":"fetchChats"
    },
	computed: {
        user(){
            return this.$store.state.userList.user
        },
        chats(){
            return this.$store.getters['userList/getChats']
        },
        searchQuery: {
            get() {
                return this.$store.state.userList.chatSearchQuery
            },
            set(val) {
                this.$store.commit('userList/SET_CHAT_SEARCH_QUERY', val)
            }
        },
	},
	methods: {
        sendMessage() {
            if (!this.form.content) { this.alertError("Please type something"); return;}
            const formData = new FormData()
            formData.append('data', JSON.stringify(this.form))
            this.$store.dispatch("changeLoaderValue", true)
            this.$http.post(`/chats/${this.$route.params.userId}/create`,formData)
            .then((response) => {
                this.$store.dispatch("changeLoaderValue", false)
                if(response.data.success){
                    this.$store.commit(`userList/ADD_USER_CHAT`, response.data.data.chat)
                    this.resetForm();
                    this.scrollMessageAreaUp();
                }
            })
        },
        scrollMessageAreaUp(){
            setTimeout(()=>{
                this.$refs.chatLogPSWindow.scrollMessageAreaUp()
            },1000)
        },
        resetForm(){
            this.showUploadFileModal = false;
            this.form.content =  "";
        },
        fetchChats(){
            this.$store.dispatch("userList/fetchChats", this.$route.params.userId)
                .then((response) => {
                    if(response.data.success){
                        this.isLoading = false
                        this.scrollMessageAreaUp()
                    }
                })
        }
	},
    created(){
        this.$store.dispatch("userList/fetchUser", this.$route.params.userId);
        this.fetchChats();
    },

}
</script>


<template>
<div>
    <simplebar data-simplebar ref="messageLogPS" style="height: calc(100vh - 300px);overflow-x: hidden;">
        <ul style="min-height: calc(100vh - 300px);" class="conversation-list mb-0 chat-app-conversation" ref="messageLog">
            <li class="clearfix" v-for="(chat, index) in chats" :key="index">
                <div class="odd" v-if="chat.from_id == activeUser.id">
                    <chat-content :chat="chat"  float="right" >
                        <template v-slot:footer-action>
                             <a class="text-success mr-2" @click.prevent="initUpdate(chat)" href="#">Edit</a>
                             <a class="text-danger" @click.prevent="deleteMessage(chat.id)" href="#">Delete</a>
                        </template>
                    </chat-content>
                </div>
                <div v-else >
                    <chat-content :chat="chat" float="left">
                        <template v-slot:footer-action>
                             <a class="text-success" @click.prevent="initReply(chat.id)" href="#">Reply</a>
                        </template>
                    </chat-content>
                </div>
            </li>
        </ul>
        <b-modal centered scrollable id="modal-1" size="sm" v-model="showUpdateModal"
             :title="isUpdateMode ? 'Update Message' : 'Reply Message'"
             header-close-variant="primary" title-class="font-18 text-primary" hide-footer>
            <div class="row justify-content-end">
                <div class="col-12 d-flex align-items-center">
                    <div class="form-group w-100">
                        <textarea  v-model="onUpdate.content" class="form-control" rows="3" placeholder="Enter something..."></textarea>
                    </div>
                </div>
                <div class="col-12">
                    <div class="text-center">
                        <button type="button" @click.prevent="isUpdateMode ? updateMessage() : replyMessage()" class="btn btn-primary mt-2">
                            <i class="mdi mdi-content-save"></i> {{isUpdateMode ? 'Update' : 'Reply'}}  </button>
                    </div>
                </div>
            </div>
        </b-modal>
    </simplebar>
    <slot name="footer"></slot>
</div>
</template>

<script>
import ChatContent from './ChatContent';

export default {
    components:{
        ChatContent
    },
    props:{
        chats:{
            type:Array,
            required:true
        }
    },
    data(){
        return {
            onUpdate : {
                content : "",
            },
            isUpdateMode : true,
            showUpdateModal : false
        }
    },
    computed: {
        activeUser() {
            return this.$store.state.AppActiveUser
        },
        user(){
            return this.$store.state.userList.user
        },
    },
    methods: {
        scrollMessageAreaUp(){
            setTimeout(()=>{
                if(this.$refs.messageLogPS){
                    this.$refs.messageLogPS.SimpleBar
                    .getScrollElement()
                    .scrollTop = this.$refs.messageLog.scrollHeight
                }
            },300)
        },
        initUpdate(msg){
            this.isUpdateMode = true;
            this.onUpdate = {
                content: msg.content,
                id: msg.id,
            }
            this.showUpdateModal = true

        },
        initReply(msgId){
            this.isUpdateMode = false;
            this.onUpdate = {
                content: "",
                id: msgId,
            }
            this.showUpdateModal = true
        },
        updateMessage() {
            if (!this.onUpdate.content) { this.alertError("Please type something"); return;}
            const formData = new FormData()
            formData.append('data', JSON.stringify(this.onUpdate))
            this.requestProcessor(formData, `${this.onUpdate.id}/update`, "UPDATE")
        },
        replyMessage() {
            if (!this.onUpdate.content) { this.alertError("Please type something"); return;}
            const formData = new FormData()
            formData.append('data', JSON.stringify({'reply': this.onUpdate.content}))
            this.requestProcessor(formData, `${this.onUpdate.id}/update`, "UPDATE")
        },
        requestProcessor(formData, url, action='ADD'){
            this.$store.dispatch("changeLoaderValue", true)
            this.$http.post(`/chats/${url}`,formData)
            .then((response) => {
                this.$store.dispatch("changeLoaderValue", false)
                if(response.data.success){
                    this.$store.commit(`userList/${action}_USER_CHAT`, response.data.data.chat)
                    this.resetForm();
                }
            })
        },
        deleteMessage(itemId){
            Swal.fire({
                title: "Are you sure?",
                text: "You are about to delete this message!",
                reverseButtons:true,
                showCancelButton: true,
                confirmButtonColor: "#e11",
                cancelButtonColor: "#111",
                confirmButtonText: "Delete it!",
            }).then((result) => {
                if (result.value) {
                    this.$store.dispatch("changeLoaderValue",true)
                    this.$http.delete(`/chats/${itemId}/delete`)
                    .then((response) => {
                        this.$store.dispatch("changeLoaderValue", false)
                        if(response.data.success){
                            this.alertSuccess(response.data.success)
                            this.$store.commit("userList/DELETE_USER_CHAT", itemId)
                        }
                    })
                }
            });
        },
        resetForm(){
            this.onUpdate = {
                content: ""
            }
            this.showUpdateModal = false
            this.isUpdateMode = true;
        },
    }

};
</script>

<style lang="scss">

.text-inherit{
    color:inherit;
}
.conversation-list {
    list-style: none;
    padding: 0 15px;
    li {
        margin-bottom: 20px;

        .conversation-actions {
            float: right;
            display: none;
        }

        &:hover {
            .conversation-actions {
                display: block;
            }
        }

        .conversation-lists{
            display: inline-block;
            position: relative;
        }
    }
    .chat-avatar {
        float: left;
        text-align: center;
        width: 42px;
        img {
            border-radius: 100%;
            width: 100%;
        }
        i {
            font-size: 12px;
            font-style: normal;
        }
    }
    .ctext-wrap {
        background: #f7f8f9;
        border-radius: 3px;
        display: inline-block;
        padding: 12px;
        position: relative;
        i {
            display: block;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            position: relative;
        }
        p {
            margin: 0;
            padding-top: 3px;
        }
        &:after {
            left: -10px;
            top: 0;
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
            border-top-color: #f7f8f9;
            border-width: 6px;
            margin-right: -1px;
            border-right-color: #f7f8f9;
        }
    }
    .conversation-text {
        float: left;
        font-size: 12px;
        margin-left: 12px;
        width: 70%;
    }
    .odd {
        color: #ffffffcc;
        a,p{
         color: #ffffffcc;
        }
        .chat-avatar {
            float: right !important;
        }
        .conversation-text {
            float: right !important;
            margin-right: 12px;
            text-align: right;
            width: 70%;
        }
        .ctext-wrap {
            background-color: #283252;
            &:after {
                border-color: transparent;
                border-left-color: #283252;
                border-top-color: #283252;
                right: -10px !important;
                left: auto;
            }
        }
        .conversation-actions {
            float: left;
        }
    }
}
</style>
